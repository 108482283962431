import ordersTypes              from "./orders.types";
import {
    handleSaveOrder,
    handleGetUserOrderHistory } from "./orders.helpers";
import { auth }                 from "./../../firebase/utils";
import { clearCart }            from './../Cart/cart.actions';
import {
    takeLatest,
    put,
    all,
    call }                      from 'redux-saga/effects';
import { setUserOrderHistory }  from "./orders.actions";

export function* getUserOrderHistory({ payload }) {
    try {
        const history = yield handleGetUserOrderHistory(payload);
        yield put(
            setUserOrderHistory(history)
        );
    } catch (error) {
        console.log(`Error fetching user order history: ${error}`);
    }
};

export function* onGetUserOrderHistoryStart() {
    yield takeLatest(ordersTypes.GET_USER_ORDER_HISTORY_START, getUserOrderHistory);
};








    export function* saveOrder({ payload }) {
        try {
            const timestamp = new Date();
            yield handleSaveOrder({
                ...payload,
                orderUserID : auth.currentUser.uid,
                dateCreated : timestamp
            });
            yield put(
                clearCart()
            )

        } catch (error) {
            console.log(`Error saving order: ${error}`);
        }
    };

    export function* onSaveOrderHistoryStart() {
        yield takeLatest(ordersTypes.SAVE_ORDER_HISTORY_START, saveOrder);
    };

    export default function* ordersSagas() {
        yield all([
            call(onSaveOrderHistoryStart),
            call(onGetUserOrderHistoryStart),
        ])
    }
