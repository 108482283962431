import { applyMiddleware, createStore } from "redux";
import createSagaMiddle                 from 'redux-saga';
import logger                           from 'redux-logger';
import thunk                            from 'redux-thunk';
import rootReducer                      from "./rootReducer";
import rootSaga                         from "./rootSaga";
import { persistStore }                 from "redux-persist"
// import { configureStore, getDefaultMiddleware}   from '@reduxjs/toolkit';
    
const sagaMiddleware        = createSagaMiddle();
export const middlewares    = [thunk, sagaMiddleware, logger];

export const store          = createStore(rootReducer, applyMiddleware(...middlewares));
sagaMiddleware.run(rootSaga);

export const persistor      = persistStore(store);

// export const store = configureStore({
//     reducer: rootReducer,
//     middleware: middlewares, 
// })
export default {
    store,
    persistor
};