import { auth } from "../../firebase/utils";

export const handleResetPasswordAPI = ( email ) => {

    const config = {
        url: 'http://localhost:3000/login'
    };

    return new Promise((resolve, reject) => {
        auth.sendPasswordResetEmail(email, config)
        .then(() => {
            console.log("Attempting to reset password");
            resolve();
            
        }).catch(() => {
            const err = ["Email not found. Please check spelling and try again."]
            console.log("Something went wrong during password reset");
            reject(err);
            
        })
    });
};