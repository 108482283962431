import styled from 'styled-components';

export const HeroContainer = styled.div`
  background-color: rgba(10, 10, 10, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 200px 0;
  
  /* border-radius: 0% 0% 50% 50%; */
`;

export const HeroText = styled.div`
  max-width: 600px;
  text-align: center;
  color: white;
`;

export const HeroLogo = styled.img`
  height: 50vh;
  width: 50vh;
  border-radius: 50% 50% 50% 50%;
  padding: 10px;
`;

export const HeroTitle = styled.h1`
  font-size: 3rem;
  margin-bottom: 20px;
`;

export const HeroSubtitle = styled.h2`
  font-size: 1.2rem;
  margin-bottom: 40px;
`;

export const HeroBGVideo = styled.video`
position    : fixed;
top: 0;
  left: 0;
  right: 0;
  bottom: 0;
width       : 100%;
height      : 100%;
object-fit  : cover;
z-index     : -1;
`;
