import React      from 'react'
import questions  from './../../components/FAQ/faq.json';
import Banner     from './../../components/FAQ';

const FAQPage = () => {
  return (
    <>
      <Banner>
        <Banner.Header>Frequently Asked Questions</Banner.Header>
        {questions.map((question) => (
          <Banner.Entity key={question.id}>
            <Banner.Question>{question.question}</Banner.Question>
            <Banner.Text>{question.answer}</Banner.Text>
          </Banner.Entity>
        ))}
        <Banner.Footer>Question not on the list? Contact us for further inquiries.</Banner.Footer>
      </Banner>
    </>
  )
}

export default FAQPage