import React from 'react';
import { FormLabel, FormRow, FormTextArea } from './index.elements';

const FormTextBox = ({ handleChange, label, ...otherProps }) => {
  return (
    <FormRow>
        {label && (
            <FormLabel>
                {label}
            </FormLabel>
        )}

        <FormTextArea onChange={handleChange} {...otherProps} />
    </FormRow>
  );
};

export default FormTextBox;
