import styled from 'styled-components';
import FormInput from '../forms/FormInput';

export const FormWrap = styled.div`
  margin: 3rem auto 0;
`;

export const Form = styled.form`
`;

export const FormTextBox = styled.textarea`
    display     : block;
    width       : 100%;
    font-size   : 1.5rem;
    line-height : 1;
    font-weight : 400;
    text-align  : left;
    padding     : 5px 5px;
    margin      : 5px auto;
    border      : 1px solid #9e9e9e;
    outline     : none;
    resize      : vertical;
    border: none;
    border-radius: 5px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    &:focus {
        outline: none;
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
    }
`;

