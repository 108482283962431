import React            from 'react';
import { useSelector }  from 'react-redux';
import {
  SidebarContainer,
  Icon,
  CloseIcon,
  SidebarWrapper,
  SidebarMenu,
  SidebarLink,
  SidebarRoute,
  SideBtnWrap
} from './sidebar.elements';

const mapStateToProps = ({ user }) => ({
  currentUser: user.currentUser
});

const Sidebar = ({ isOpen, toggle }) => {

  const {currentUser}             = useSelector(mapStateToProps);

  return (
    <SidebarContainer isOpen={isOpen} onClick={toggle}>
      <Icon onClick={toggle}>
      <CloseIcon />
      </Icon>
      <SidebarWrapper>
        <SidebarMenu>
          <SidebarLink
            to        = 'about'
            spy       = {true}
            exact     = 'true'
            offset    = {-80}
            smooth    = {true}
            onClick   = {toggle}
            duration  = {500}
          >
            About
          </SidebarLink>
          <SidebarLink
            to        = 'discover'
            spy       = {true}
            exact     = 'true'
            offset    = {-80}
            smooth    = {true}
            onClick   = {toggle}
            duration  = {500}
          > Discover </SidebarLink>

          <SidebarLink
            to        = 'services'
            spy       = {true}
            exact     = 'true'
            offset    = {-80}
            smooth    = {true}
            onClick   = {toggle}
            duration  = {500}
          >
            Services
          </SidebarLink>
          <SidebarLink
            to        = 'signup'
            spy       = {true}
            exact     = 'true'
            offset    = {-80}
            smooth    = {true}
            onClick   = {toggle}
            duration  = {500}
          >
            Sign Up
          </SidebarLink>
        </SidebarMenu>
        {currentUser && (
          <>
            <SideBtnWrap>
              <SidebarRoute to='/dashboard'>Dashboard</SidebarRoute>
            </SideBtnWrap>
          </>
        )}

        {!currentUser && (
          <>
            <SideBtnWrap>
              <SidebarRoute to='/signin'>Sign In</SidebarRoute>
            </SideBtnWrap>
          </>
        )}
      </SidebarWrapper>
    </SidebarContainer>
  );
};

export default Sidebar;
