const userTypes = {

    EMAIL_SIGN_IN_START     : 'EMAIL_SIGN_IN_START',
    SIGN_IN_SUCCESS         : 'SIGN_IN_SUCCESS',
    CHECK_USER_SESSION      : 'CHECK_USER_SESSION',
    SIGN_OUT_USER_START     : 'SIGN_OUT_USER_START',
    SIGN_OUT_USER_SUCCESS   : 'SIGN_OUT_USER_SUCCESS',
    SIGN_UP_USER_START      : 'SIGN_UP_USER_START',
    USER_ERROR              : 'USER_ERROR',
    RESET_PASSWORD_START    : 'RESET_PASSWORD_START',
    RESET_PASSWORD_SUCCESS  : 'RESET_PASSWORD_SUCCESS',
    RESET_USER_STATE        : 'RESET_USER_STATE',
    GOOGLE_SIGN_IN_START    : 'GOOGLE_SIGN_IN_START'
};

export default userTypes;