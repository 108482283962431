import { auth }             from '../../firebase/utils';
import {
    setProducts,
    fetchProductsStart,
    setProduct }            from './products.actions';
import {
    takeLatest,
    put,
    all,
    call }                  from 'redux-saga/effects';
import productsTypes        from './product.types';
import {
    handleAddProduct,
    handleFetchProducts,
    handleDeleteProduct,
    handleFetchProduct }   from './products.helpers';

export function* addProduct({ payload }) {

    try {
        const timestamp = new Date();
        yield handleAddProduct({
            ...payload,
            productAdminUserUID : auth.currentUser.uid,
            dateCreated         : timestamp
        });
        yield put(
            fetchProductsStart()
        );
    } catch (err) {
        // console.log(err)
    }
}

export function* onAddProductStart() {
    yield takeLatest(productsTypes.ADD_NEW_PRODUCT_START, addProduct)
}

export function* fetchProducts({ payload }) {
    try {
        const products = yield handleFetchProducts(payload);
        yield put(
            setProducts(products)
        );

    } catch (err) {
        // console.log(err);
    }
}

export function* onFetchProductsStart() {
    yield takeLatest(productsTypes.FETCH_PRODUCTS_START, fetchProducts)
}

export function* deleteProduct({ payload }) {
    try {
        yield handleDeleteProduct(payload);
        yield put(
            fetchProductsStart()
        );
    } catch (err) {
        // console.log(err)
    }
}

export function* onDeleteProductStart() {
    yield takeLatest(productsTypes.DELETE_PRODUCT_START, deleteProduct)
}

export function* fetchProduct({ payload }) {
    try {
        const product = yield handleFetchProduct(payload);
        yield put(
            setProduct(product)
        );

    } catch (err) {
        // console.log(`ERROR: ${err}`)
    }
}

export function* onFetchProductStart() {
    yield takeLatest(productsTypes.FETCH_PRODUCTS_START, fetchProduct)
}


export default function* productsSagas() {
    yield all([
        call(onAddProductStart),
        call(onFetchProductsStart),
        call(onDeleteProductStart),
        call(onFetchProductStart),
    ])
}