import Container from '../../components/Container';
import Header from '../../components/Header';

const SignUpPage = () => {
  return (
    <Container>
      <Header />
      <h1>Sign Up Page</h1>
    </Container>
  );
};

export default SignUpPage;
