import styled from "styled-components";

export const Btn = styled.button`
    display         : block;
    width           : 100%;
    font-size       : 1.8rem;
    line-height     : 1;
    font-weight     : 300;
    text-transform  : uppercase;
    color           : white;
    background      : black;
    padding         : 1rem 1rem;
    margin          : 0 auto;
    border          : 0;
    outline         : none;
    cursor          : pointer;
    margin          : 4px;
    border-radius   : 8px;
`