import React from 'react'
import { FormLabel, FormRow, FormInputView } from './index.elements'

const FormInput = ({ handleChange, label, ...otherProps }) => {
  return (
    <FormRow>
        {label && (
            <FormLabel>
                {label}
            </FormLabel>
        )}

        <FormInputView onChange={handleChange} {...otherProps} />
    </FormRow>
  )
}

export default FormInput