import styled from "styled-components";

export const AuthWrap = styled.div`
    display     : block;
    width       : 100%;
    max-width   : 40.0rem;
    margin      : 4rem auto 6rem;
    border      : 1px solid black;
`

export const Wrap = styled.div`
    padding         : 10px;
`

export const AuthH2   = styled.h2`
    font-size       : 2.2rem;
    line-height     : 1;
    font-weight     : 400;
    text-transform  : uppercase;
    text-align      : center;
    display         : block;
    width           : 100%;
    padding         : 0;
    margin          : 0 auto 3rem;
`

export const AuthChildren   = styled.div`

`

export const AuthA = styled.a`
    display     : block;
    width       : 100%;
    margin      : 0.5rem auto 0;
    text-align  : center;
    font-size   : 14px;
`