import React from 'react'
import { AuthA, AuthChildren, AuthH2, AuthWrap, Wrap } from './index.elements'

const AuthWrapper = ({ headline, children, destination1, href1, destination2, href2 }) => {
  return (
    <AuthWrap>
        <Wrap>
            {headline && <AuthH2>{headline}</AuthH2>}
            <AuthChildren>
                {children && children}
            </AuthChildren>
            <AuthA href={href1}>{destination1 && destination1}</AuthA>
            <AuthA href={href2}>{destination2 && destination2}</AuthA>
        </Wrap>
    </AuthWrap>
  )
}

export default AuthWrapper