import React from 'react'
import { Btn } from './index.elements'

const Button = ({ children, ...otherProps }) => {
  return (
    <Btn {...otherProps}>
        {children}
    </Btn>
  )
}

export default Button